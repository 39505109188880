<template>
  <div class="content__container">
    <div class="quiz-left-colum" :class="'step-'+ stat.level">
      <img v-show="item == stat.level" v-for="item in stat.levels" style="display: none" :src="`/images/quiz/bg-step-${item}.png`" alt="">
    </div>
    <main v-if="formData && stat.level" class="content__content pRel" key="t1">
      <div class="content__wrapper heightAll">
        <div v-if="stat.level" class="content__progressBox">
          <div class="pRel">
            <div class="medium text_md progress__text layout pAbs">{{ stat.level }}/{{ stat.levels }}</div>
            <svg viewBox="0 0 32 32" class="progress__container">
              <circle class="progress__circleEmpty" r="16" cx="16" cy="16"></circle>
              <circle class="progress__circleFull" r="16" cx="16" cy="16" :stroke-dasharray="progressBar(stat.progress)" ></circle>
            </svg>
          </div>
        </div><!--end content__progressBox -->
        
        <div class="content__form">
          <div class="content__formWrapper">
            <div v-if="formData.heading" class="form__title title__form bold">{{ formData.heading }}</div>
            
            <div class="content__formContainer">
              <template v-if="formData.fields">
                <dynamic-form/>
              </template>
              <template v-else-if="formData.component">
                <component :is="formData.component"/>
              </template>
            </div>
          </div>
          <div class="sale-progress__wrapper">
            <div class="sale-progress__bar">
              <div class="sale-progress__min"></div>
              <div class="sale-progress__auto">
                <div :style="{ 'width': stat.progress +'%' }" class="sale-progress"><div class="sale-progress__sale">{{ stat.level * 5 }}%</div></div>
              </div>
              <div class="sale-progress__label">Ваша скидка:</div>
            </div>
          </div>
        </div><!--end content__form ---->
      </div>
    </main>
    <div v-else-if="formData" class="listing__layoutAll growOn pRel" key="t2">
      <component :is="formData.component"/>
    </div>
    
  </div>
</template>

<script>
import Quiz from '../quiz/App'
import DynamicForm from '@/modules/quiz-landing/components/DynamicFormComponent'
import PriceForm from '@/modules/quiz-landing/components/PriceFormComponent'
import PriceFormRadio from '@/modules/quiz-landing/components/PriceFormRadioComponent'
import SendForm from '@/modules/quiz-landing/components/SendFormComponent'
import FinishingFormComponent from '@/modules/quiz-landing/components/FinishingFormComponent'

export default {
  mixins: [Quiz],
  components: {
    'dynamic-form': DynamicForm,
    'price-form': PriceForm,
    'price-form-radio': PriceFormRadio,
    'send-form': SendForm,
    'finishing-form': FinishingFormComponent
  },

  methods: {
    progressBar (val) {
      return val + ' 100';
    },
  }
}
</script>
